import actions from '../actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { IFirebaseUser, TourVisibility } from '../../../models';
import { commonSelectors } from '../../common';
import { takeConfirmation } from '../../utils/sagas';
import { getServiceProvider, IServiceProvider } from '../../../services';
import routes from '../../utils/routes';
import { getType } from 'typesafe-actions';
import selectors from '../selectors';

const updateTourVisibilitySaga = takeEvery(getType(actions.updateTourVisibility.request), function* (
  action: ReturnType<typeof actions.updateTourVisibility.request>
) {
  const tourVisibility = action.payload;

  switch (tourVisibility) {
    case TourVisibility.FRIENDS:
      yield checkUserStatus(() => updateState(tourVisibility));
      break;
    case TourVisibility.PUBLIC:
      yield checkUserStatus(checkReviewed);
      break;
    default:
      yield updateState(tourVisibility);
  }
});

function* checkUserStatus(next: () => void) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const user: IFirebaseUser | undefined = yield select(commonSelectors.selectUser);
  const isAnonymous = user?.anonymous ?? true;

  if (isAnonymous) {
    const confirmed = yield takeConfirmation({
      titleKey: 'create_tour.visibility.dialog_title',
      content: { key: 'create_tour.visibility.registration_required' },
      positiveKey: 'common.ok',
    });
    if (confirmed) {
      serviceProvider.navigationService.navigateI18n(routes.SIGN_IN);
    }
  } else {
    yield call(next);
  }
}

function* checkReviewed() {
  const isReviewed: boolean = yield select(selectors.selectIsReviewed);

  if (!isReviewed) {
    const requested = yield takeConfirmation({
      titleKey: 'create_tour.visibility.public.dialog_title',
      content: { key: 'create_tour.visibility.public.review_dialog' },
      positiveKey: 'create_tour.visibility.public.request_review',
    });

    if (requested) {
      yield put(actions.requestReview.request());
    }
  } else {
    yield updateState(TourVisibility.PUBLIC);
  }
}

function* updateState(visibility: TourVisibility) {
  yield put(actions.updateTourField('visibility', visibility, true));
}

export default [updateTourVisibilitySaga];
