import { RootState } from '../../store/root';
import { createSelector } from 'reselect';
import { equals } from '../../utils/helper/deep-equal';
import { SettingsValidation, validate } from './utils/validation';
import { ICreateTourState, IImageState, ITourState, ITourStopState } from './models/state';
import { ImageListPath, selectImages as selectImageHelper } from './utils/imagePathHelper';
import { AvailableSettings, AvailableSettingsKey, ITourSettings, IWaypoint, TourVisibility } from '../../models';
import ReviewStatus from '../../models/ReviewStatus';
import { BooleanPropertyKeys, NonBooleanPropertyKeys } from '../../utils/types/utilTypes';

const MAX_NUMBER_OF_IMAGES = 5;

const selectIsSaving = (state: RootState) => state.createTour.isSaving;

const selectErrorDevMessage = (state: RootState) => state.createTour.saveError?.devDescription;

const selectCreateTourState = (state: RootState) => state.createTour;

const selectTour = (state: RootState) => state.createTour.tour;

const selectIsNewTour = createSelector(selectTour, tour => (tour?.id ?? 0) === 0);

const selectTourState = (state: RootState): ITourState => state.createTour.tourState;

const selectStartLocation = createSelector(selectTourState, tourState => tourState.startLocation);

const selectEndLocation = createSelector(selectTourState, tourState => tourState.endLocation);

const selectStartText = createSelector(selectTourState, tourState => tourState.startText);

const selectEndText = createSelector(selectTourState, tourState => tourState.endText);

const selectIsRoundTrip = createSelector(selectTourState, tourState => tourState.isRoundTrip);

const selectTourTitle = createSelector(selectTourState, tourState => tourState.title);

const selectTourCode = createSelector(selectTour, (tour): string | undefined => tour?.shortLink);

const selectDescription = createSelector(selectTourState, tourState => tourState.description);

const selectLanguage = createSelector(selectTourState, tourState => tourState.language);

const selectHasEditingSnapshot = (state: RootState) => !!state.createTour.editingSnapshot;

const selectRawTourStops = createSelector(selectTourState, tourState => tourState.stops);

const selectTourStops = createSelector(selectRawTourStops, stops => {
  return stops?.sortBy('orderId', 'ASC');
});

const selectCountTourStops = createSelector(selectRawTourStops, stops => stops?.length ?? 0);

const selectIsFetching = (state: RootState) => state.createTour.isFetching;

const selectFetchError = (state: RootState) => state.createTour.fetchError;

const selectIsStateDifferentFromSavedTour = createSelector(selectCreateTourState, createTourState => {
  if (!createTourState.editingSnapshot) {
    return false;
  }
  return !equals(createTourState.tourState, createTourState.editingSnapshot);
});

const selectSaveError = (state: RootState) => state.createTour.saveError;

const selectCanSave = createSelector(
  [selectIsStateDifferentFromSavedTour, selectSaveError],
  (isStateDifferent, saveError) => isStateDifferent || !!saveError
);

function createValidation(state: ICreateTourState) {
  return validate(state.tourState);
}

const selectValidation = createSelector(selectCreateTourState, createValidation);

const selectTourStopValidation = (state: RootState, stopId: number) => selectValidation(state).stopsValidation[stopId];

const selectSettingsValidation = createSelector(
  selectValidation,
  (validation): SettingsValidation | undefined => validation.settingsValidation
);

const selectLatestTourStop = createSelector(selectTourStops, (stops): ITourStopState | undefined => {
  return stops.length > 0 ? stops[stops.length - 1] : undefined;
});

const selectTourStopByLocalId = (state: RootState, localId: number) => {
  return state.createTour.tourState.stops.find((s: ITourStopState) => s.localId === localId);
};

const selectTourStopTitle = createSelector(selectTourStopByLocalId, tourStop => {
  return tourStop?.title;
});

const selectDirections = createSelector(selectTourStopByLocalId, tourStop => {
  return tourStop?.directions;
});

const selectWaypointsEnabled = createSelector(selectTourStopByLocalId, tourStop => {
  return tourStop?.waypointsEnabled;
});

const selectWaypoints = createSelector(selectTourStopByLocalId, (tourStop): IWaypoint[] => {
  return tourStop?.waypoints ?? [];
});

// Selects the location of the current stop, or of previous stop, otherwise falls back to start location or undefined
const selectDefaultWaypointLocation = (state: RootState, localId: number): IWaypoint | undefined => {
  const stops: ITourStopState[] = state.createTour.tourState.stops;
  const currentStop = stops.find((s: ITourStopState) => s.localId === localId);
  if (currentStop && currentStop.waypoints) {
    if (currentStop.waypoints.length > 0) {
      return currentStop.waypoints[0];
    }

    type OrderedWaypoints = { orderId: number; waypoints?: IWaypoint[] };
    const previous = stops
      .filter(s => s.orderId < currentStop.orderId)
      .reduce((prev: OrderedWaypoints, curr: OrderedWaypoints) => (curr.orderId > prev.orderId ? curr : prev), {
        orderId: -Infinity,
      } as OrderedWaypoints);

    if (previous?.waypoints?.[0]) {
      return previous.waypoints[0];
    }
  }
  return state.createTour.tourState.startLocation;
};

const selectInformation = createSelector(selectTourStopByLocalId, tourStop => {
  return tourStop?.information;
});

const selectOrderId = createSelector(selectTourStopByLocalId, tourStop => {
  return tourStop?.orderId;
});

const selectTask = createSelector(selectTourStopByLocalId, tourStop => {
  return tourStop?.task;
});

const selectSettings = createSelector(selectTourState, tour => {
  return tour.settings;
});

const selectChipSettings = (key: NonBooleanPropertyKeys<ITourSettings>) =>
  createSelector([selectSettings, selectSettingsValidation], (settings, validation) => ({
    all: AvailableSettings[key as AvailableSettingsKey],
    selected: settings[key] ?? [],
    isValid: validation && validation.hasOwnProperty(key) ? validation[key] : undefined,
  }));

const selectBooleanSetting = (key: BooleanPropertyKeys<ITourSettings>) =>
  createSelector(selectSettings, settings => settings[key]);

const selectImagePath = (state: RootState, path: ImageListPath) => path;

const selectImages = createSelector([selectTourState, selectImagePath], (tourState, path) => {
  const images = selectImageHelper(tourState, path);
  if (images) {
    return images.filter(img => img.type === path.listType);
  }
});

const selectSortedImages = createSelector(selectImages, (images): IImageState[] => {
  return images ? images.sortBy('orderId', 'ASC') : [];
});

const selectCanAddImages = createSelector([selectSortedImages], (images): boolean => {
  return images.length < MAX_NUMBER_OF_IMAGES;
});

const selectImageById = createSelector(
  [selectImages, (state: RootState, props: { localId: number }) => props],
  (images, { localId }): IImageState | undefined => {
    return images ? images.find(img => img.localId === localId) : undefined;
  }
);

const selectRecentlyAddedImageLocalId = createSelector(selectImages, images => {
  return images ? Math.max(...Object.keys(images).map(id => parseInt(id, 10))) : 0;
});

const selectShowGuidanceDialog = (state: RootState) => state.createTour.showGuidanceDialog;

const selectTourVisibilityOptions = (state: RootState): TourVisibility[] =>
  Object.keys(TourVisibility).map(k => (TourVisibility as any)[k]);
const selectCurrentTourVisibility = createSelector(selectTourState, tourState => tourState.visibility);
const selectIsReviewed = createSelector(selectTourState, tourState => tourState.reviewStatus === ReviewStatus.REVIEWED);

const selectLockChanges = (state: RootState) => state.createTour.tour?.lockChanges ?? false;

export default {
  selectIsSaving,
  selectErrorDevMessage,
  selectTour,
  selectIsNewTour,
  selectTourCode,
  selectTourTitle,
  selectStartLocation,
  selectEndLocation,
  selectDescription,
  selectLanguage,
  selectStartText,
  selectEndText,
  selectOrderId,
  selectIsRoundTrip,
  selectIsStateDifferentFromSavedTour,
  selectHasEditingSnapshot,
  selectCreateTourState,
  selectSaveError,
  selectTourStops,
  selectCountTourStops,
  selectLatestTourStop,
  selectTourStopById: selectTourStopByLocalId,
  selectTourStopTitle,
  selectDirections,
  selectWaypointsEnabled,
  selectWaypoints,
  selectDefaultWaypointLocation,
  selectInformation,
  selectTask,
  selectValidation,
  selectTourStopValidation,
  selectIsFetching,
  selectFetchError,
  selectSettings,
  selectSortedImages,
  selectImageById,
  selectRecentlyAddedImageLocalId,
  selectCanAddImages,
  selectCanSave,
  selectTourVisibilityOptions,
  selectCurrentTourVisibility,
  selectIsReviewed,
  selectChipSettings,
  selectBooleanSetting,
  selectShowGuidanceDialog,
  selectLockChanges,
};
